import { Atom } from ":mods";
import { AStageModule } from "../models";

export function createSubmoduleInputs(details?: AStageModule) {
  const ID = Atom.Form.createInput(undefined, { disabled: true });
  const Name = Atom.Form.createInput(details?.name, { required: true });

  const Actions = Atom.Form.createFormActions(
    {
      ID,
      Name,
    },
    {
      defaultCase: "snake",
    }
  );

  // const values = Actions.getValuesUppercase({TotalMinutesQuiz: "kebab", TotalMinutesVideo: "snake"});
  return {
    Actions,
    ID,
    Name,
  };
}
